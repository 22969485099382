var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "height100" },
    [
      _c(
        "header-title",
        { attrs: { title: _vm.title } },
        [
          _c(
            "rs-button",
            {
              staticClass: "button-click",
              attrs: { slot: "center", size: "small", type: "primary" },
              on: { click: _vm.handleEdit },
              slot: "center",
            },
            [_vm._v("编辑")]
          ),
        ],
        1
      ),
      _c(
        "rs-form",
        {
          ref: "editForm",
          staticClass: "form-readonly demo-ruleForm",
          staticStyle: { "padding-right": "20px", "padding-top": "5px" },
          attrs: {
            size: "small",
            model: _vm.editForm,
            "label-width": "100px",
            "label-position": "right",
            "status-icon": "",
          },
        },
        [
          _c(
            "rs-col",
            { attrs: { span: 12 } },
            [
              _c("rs-form-item", { attrs: { label: "岗位名称:" } }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.editForm.stationName) +
                    "\n            "
                ),
              ]),
            ],
            1
          ),
          _c(
            "rs-col",
            { attrs: { span: 12 } },
            [
              _c("rs-form-item", { attrs: { label: "所属组织:" } }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.editForm.organizationName) +
                    "\n            "
                ),
              ]),
            ],
            1
          ),
          _c(
            "rs-col",
            { attrs: { span: 12 } },
            [
              _c("rs-form-item", { attrs: { label: "岗位描述:" } }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.editForm.remark) +
                    "\n            "
                ),
              ]),
            ],
            1
          ),
          _c(
            "rs-col",
            { attrs: { span: 12 } },
            [
              _c("rs-form-item", { attrs: { label: "岗位状态:" } }, [
                _c("span", [
                  _vm._v(_vm._s(_vm._f("status")(_vm.editForm.status))),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "rs-col",
            { attrs: { span: 12 } },
            [
              _c("rs-form-item", { attrs: { label: "创建人:" } }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.editForm.createName) +
                    "\n            "
                ),
              ]),
            ],
            1
          ),
          _c(
            "rs-col",
            { attrs: { span: 12 } },
            [
              _c("rs-form-item", { attrs: { label: "创建时间:" } }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.editForm.createTime) +
                    "\n            "
                ),
              ]),
            ],
            1
          ),
          _c(
            "rs-col",
            { attrs: { span: 12 } },
            [
              _c("rs-form-item", { attrs: { label: "更新人:" } }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.editForm.modifyName) +
                    "\n            "
                ),
              ]),
            ],
            1
          ),
          _c(
            "rs-col",
            { attrs: { span: 12 } },
            [
              _c("rs-form-item", { attrs: { label: "更新时间:" } }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.editForm.modifyTime) +
                    "\n            "
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }