<template>
    <div class="height100">
        <header-title :title="title">
            <rs-button size="small" slot="center" class="button-click" type="primary" @click="handleEdit">编辑</rs-button>
        </header-title>
        <rs-form size="small" class="form-readonly demo-ruleForm" :model="editForm" label-width="100px" style="padding-right: 20px;padding-top: 5px;" label-position="right" status-icon ref="editForm">
            <rs-col :span="12">
                <rs-form-item label="岗位名称:">
                    {{editForm.stationName}}
                </rs-form-item>
            </rs-col>
            <rs-col :span="12">
                <rs-form-item label="所属组织:">
                    {{editForm.organizationName}}
                </rs-form-item>
            </rs-col>
            <rs-col :span="12">
                <rs-form-item label="岗位描述:">
                    {{editForm.remark}}
                </rs-form-item>
            </rs-col>
            <rs-col :span="12">
                <rs-form-item label="岗位状态:">
                    <span>{{editForm.status | status}}</span>
                </rs-form-item>
            </rs-col>
            <rs-col :span="12">
                <rs-form-item label="创建人:">
                    {{editForm.createName}}
                </rs-form-item>
            </rs-col>
            <rs-col :span="12">
                <rs-form-item label="创建时间:">
                    {{editForm.createTime}}
                </rs-form-item>
            </rs-col>
            <rs-col :span="12">
                <rs-form-item label="更新人:">
                    {{editForm.modifyName}}
                </rs-form-item>
            </rs-col>
            <rs-col :span="12">
                <rs-form-item label="更新时间:">
                    {{editForm.modifyTime}}
                </rs-form-item>
            </rs-col>
        </rs-form>
    </div>
</template>

<script>
    import Http from '@/api/organizationApi'
    import utils from '@/utils/utils'
    import headerTitle from '@/components/header'
    export default {
        components: {
            headerTitle
        },
        data(){
            return{
                title: '岗位详情',
                rules: {
                    stationName: [
                        {required: true, message: '请输入岗位名称', trigger: 'change'},
                        { max: 20, message: '最多20个字符', trigger: 'change' }
                    ],
                    status: [{required: true, message: '请选择状态', trigger: 'change'}],
                    organizationSysCode: [{required: true, message: '请选择所在组织', trigger: 'change'}],
                    sort: [
                        { validator: (rule, value, callback)=>{
                            if(value!='') {
                                if (!Number.isInteger(value)) {
                                    callback(new Error('请输入数字值'));
                                } else {
                                    callback();
                                }
                            } else {
                                callback();
                            }
                        }, trigger: 'change' }
                    ]
                },
                editForm: {},
                form: {},
                id: ''
            }
        },
        
        methods:{
            // 获取详情
            handleDetail() {
                let user = utils.getSession("userInfo");
                Http.queryStationDetail({stataionId: this.id}).then(res=>{
                    this.editForm = res.data.data
                    this.form = {
                        companyCode: user.companyVo.companyCode,
                        sort: res.data.data.sort?res.data.data.sort:'',
                        remark: res.data.data.remark,
                        organizationName: res.data.data.organizationName,
                        organizationSysCode: res.data.data.organizationSysCode,
                        stationName: res.data.data.stationName,
                        status: res.data.data.status,
                        organizationName: res.data.data.organizationName,
                        stationId: this.id
                    }
                })
            },
            handleEdit() {
                this.$router.push(`/stationEdit?id=${this.id}`)
            },
            // 保存请求
            submit(form) {
                Http.saveStation(form).then(res=>{
                    this.$message({
                        message: '操作成功！',
                        type: 'success'
                    });
                    this.handleDetail()
                }).catch(err=>{})
            },
        },
        created(){
            this.id = this.$route.query.id
            this.handleDetail()
        },
        filters:{
            status(val) {
                return val == 1?'已启用':'已停用'
            }
        },
    }
</script>

<style scoped>
.height100 {
    height: 100%;
}
.box {
    /* padding: 10px 20px; */
    background: #ffffff;
    height: calc(100% - 19px);
    margin: 4px 0 15px 0;
    overflow: auto;
}
.box-l {
    border: 1px solid rgba(0,0,0,0.1);
    margin-left: 1.2%;
    margin-right: 1.2%;
}
.box-r {
    border: 1px solid rgba(0,0,0,0.1);
    margin-left: 1.2%;
}
.add-role-button {
    border-bottom: 1px solid #EDEDED;
    padding: 10px 13px 9px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.edit-button {
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid #EDEDED;
    padding: 3px 9px;
}
.add_auth_button {
    float: right;
    margin-right: 9px;
}
.table-box {
    padding: 0 9px 15px 13px;
}
.rs-form-item{
    margin-bottom:0;
}
/deep/.rs-form-item__label {
    color: #666666;
}
</style>
    